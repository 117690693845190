/* AboutUsBanner.css */

.simplify-management-section {
  padding: 50px 0;
  background-color: #f9fafc;
}

.section-title {
  font-size: 32px;
  font-weight: bold;
  line-height: 1.4;
  margin-bottom: 20px;
}

.section-subtitle {
  font-size: 16px;
  color: #5f5f5f;
  margin-bottom: 30px;
}

.title-divider {
  width: 80px;
  margin: 20px auto;
  border: 2px solid #4e63e3; /* Blue accent color */
}

/* Platform icons styling */
.platform-icons-row {
  margin-top: 30px;
}

.platform-icon {
  margin-bottom: 20px;
}

.icon-card {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
}

.icon-card img {
  max-width: 50px;
  margin-bottom: 10px;
}

.icon-card p {
  font-size: 14px;
  color: #333;
  font-weight: 500;
}
