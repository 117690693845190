.home-section {
  position: relative;
  bottom: 16px;
  margin: 0px;
}

.home-banner-row {
  margin: 0px;
}

.home-customer-highlight-row {
  position: relative;
  top: 16px;
  margin: 0px;
}

.home-customer-highlight-border {
  padding: 0px;
}

.home-services-row {
  margin: 0px;
}
