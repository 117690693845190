/* ContactForm.css */

.contact-section {
  padding: 50px 0;
}

.contact-image img {
  border-radius: 10px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form-heading {
  font-size: 24px;
  color: #333;
  font-weight: 600;
  margin-bottom: 10px;
}

.form-underline {
  width: 50px;
  height: 4px;
  background-color: #4e63e3;
  margin-bottom: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
  }

  .contact-image img {
    height: 300px;
  }
}
