.identity-subtitle {
    justify-content: left;
    font-weight: 100;
    align-items: left;
  }
  h2.identity-title {
    font-size: 32px;
    font-weight: bold;
    line-height: 1.4;
    margin-bottom: 20px;
    text-align: left;
  }
  h4.identity-title {
    font-size: 24px;
    font-weight: 300;
    line-height: 1.4;
    margin-bottom: 20px;
    text-align: left;
  }
  h6.identity-title {
    font-size: 20px;
    font-weight: 300;
    line-height: 1.4;
    margin-bottom: 20px;
    text-align: left;
  }

  .margin-left20
  {
    margin-left: 20px;
  }
  .margin-left30
  {
    margin-left: 30px;
  }