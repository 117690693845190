.mission-vision-section {
  color: #000;
  padding: 60px 0;
}

.section-heading {
  font-size: 24px;
  font-weight: 600;
  color: #000;
}

.heading-underline {
  width: 50px;
  height: 4px;
  background-color: #4e63e3; /* Blue underline */
  margin: 10px 0 20px 0;
}

.section-text {
  font-size: 16px;
  line-height: 1.6;
  color: #000; /* Black text */
}

/* Responsive padding */
@media (max-width: 768px) {
  .mission-vision-section {
    padding: 40px 20px;
  }
}
