.hero-section {
  padding: 50px 0;
  background-color: #f9f9f9;
  position: relative;
  overflow: hidden;
}

.effortless-title {
  color: #f1f3f7;
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.main-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #fff;
}

.sub-title {
  font-size: 1.1rem;
  margin-bottom: 40px;
  color: #fff;
}

.button-group {
  margin-bottom: 40px;
  display:none;
}

.btn-get-started {
  margin-right: 10px;
}

.btn-schedule-demo {
  margin-left: 10px;
}

.devices-row {
  margin-top: 40px;
}

.device-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
  background-image: url('../../../public/asserts/images/home.jpeg');
  background-size: cover;
}
