.about-us-banner-section {
  position: relative;
  bottom: 17px;
  margin: 0px;
}

.about-us-banner-col {
  position: relative;
  padding-left: 0px;
  padding-right: 0px;
}

.who-we-are-main-section,
.mission-vision-section {
  margin-right: 0px;
  margin-left: 0px;
}

.mission-vision-col {
  padding-left: 0px;
  padding-right: 0px;
}
