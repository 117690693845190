/* Services.css */
.image-section {
  text-align: center;
  padding: 50px;
}

.image-section h2 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
}

.image-section h6 {
  font-size: 18px;
  font-weight: 200;
  margin-bottom: 20px;
}

.image-section p {
  font-size: 16px;
  color: #555;
  margin-bottom: 40px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.image-row {
  display: flex;
  justify-content: center; /* Center cards horizontally */
  gap: 20px;
  flex-wrap: wrap; /* Allows wrapping for responsiveness */
}

.image-card {
  width: 300px; /* Set the width of the cards */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.image-card a {
  color: #0066ff;
  text-decoration: none;
  font-weight: bold; 
}

.image-card img {
  height: 183px;
  width:100%;
}

