/* Container styles for the "Who We Are" section */
.who-we-are-section {
    padding: 50px 0;
  }
  
  .section-title {
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 40px;
  }
  
  /* Image and Text column styles */
  .text-col {
    justify-content: center;
    align-items: center;
  }

  .image-col {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Card styling for the image */
  .image-card {
    border: none;
    box-shadow: none;
  }
  
  .company-image {
    max-width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Text card styling */
  .text-card {
    border: none;
    background-color: transparent;
    margin-bottom: 20px;
  }
  
  .text-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .text-description {
    font-size: 16px;
    color: #555;
    line-height: 1.6;
  }
  