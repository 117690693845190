/* CustomersHighlight.css */

.customer-highlight-companies-section {
  padding: 50px 0;
  background-color: #f9f9f9;
  border-top: 1px solid #e0e0e0;
}

.section-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 40px;
}

.logo-row {
  display: flex;
  justify-content: center; /* Ensure row content is centered */
  align-items: center; /* Ensure logos are vertically centered */
  flex-wrap: wrap; /* Allow multiple rows if needed */
  margin-bottom: 20px;
}

.logo-col {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
}

.company-logo {
  max-width: 50%;
  height: auto;
  padding: 10px;
}

.justify-content-center {
  margin-right: 0px;
  margin-left: 0px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .logo-col {
    padding: 10px 0;
  }
}
